export const schemeUrl = {
  profile: 'tmap://setting-myinfo',
  point: 'tmap://paypoint',
  biz_place: 'tmap://biz-place',
}

export const envServiceUrl = {
  tmapBizInfo: {
    dev: 'https://tmobi-board-dev.tmapmobility.com/BOARD-STATIC/61',
    stg: 'https://tmobi-board-stg.tmapmobility.com/BOARD-STATIC/90',
    prd: 'https://board.tmapmobility.com/BOARD-STATIC/9',
  },
}

export interface BizPlaceBannerType {
  isShow: boolean
  url: string
}

export const staticBizPlaceBanner: BizPlaceBannerType = { isShow: true, url: 'tmap://biz-place' }

export interface GroupListType {
  name: string
  url: string
  redDotItem?: string
  log_action_id?: string
}

export interface ServiceListType {
  groupName: string
  groupCode: string
  groupList: GroupListType[]
}

export const staticServiceList: ServiceListType[] = [
  {
    groupName: '결제관리',
    groupCode: 'pay_manage',
    groupList: [
      {
        name: '이용내역',
        url: 'tmap://usage-history',
        log_action_id: 'tap.usage',
      },
      {
        name: '결제 관리',
        url: 'payment',
        log_action_id: 'tap.pay',
      },
      {
        name: '제휴 쿠폰',
        url: 'tmap://promotion',
        log_action_id: 'tap.coupon',
      },
    ],
  },
  {
    groupName: '서비스 모아보기',
    groupCode: 'service_list',
    groupList: [
      {
        name: '전체 서비스',
        url: 'tmap://vertical?serviceid=my&pageid=all_services',
        redDotItem: 'HOME',
        log_action_id: 'tap.whole_service',
      },
      {
        name: '비즈플레이스',
        url: 'tmap://biz-place',
        log_action_id: 'tap.biz_place',
      },
      {
        name: '연구소',
        url: 'tmap://setting-laboratory',
        redDotItem: 'LABORATORY',
        log_action_id: 'tap.laboratory',
      },
    ],
  },
  {
    groupName: '고객지원',
    groupCode: 'customer_support',
    groupList: [
      {
        name: '고객센터',
        url: 'tmap://cs',
        redDotItem: 'VOC',
        log_action_id: 'tap.voc',
      },
      {
        name: '공지사항/이벤트',
        url: 'tmap://communication',
        redDotItem: 'COMMUNICATION',
        log_action_id: 'tap.communication_event',
      },
      {
        name: '장소제안',
        url: 'tmap://tip-off',
        log_action_id: 'tap.poi_proposal',
      },
      {
        name: '약관',
        url: 'tmap://webview?service=policy&id=1&params=termsCode%3DLIST-TERMS-TMAP-MAIN%26appCode%3DTMAP%26headerYn%3Dy%26headerTitle%3D%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80',
        log_action_id: 'tap.policy',
      },
    ],
  },
]
