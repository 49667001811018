import { datadogRum } from '@datadog/browser-rum'
import { init as initFrontmanClient } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { TmapLog } from '@tmap-web-lib/tmap-log-client'
import { getSearchParams } from '@tmap-web-lib/utils'
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'
import { useAtom } from 'jotai'
import { toLower } from 'lodash-es'
import mixpanel from 'mixpanel-browser'
import process from 'process'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UAParser } from 'ua-parser-js'

import { initMockClient } from '@/apis'
import { webEnvState } from '@/atoms'
import { firebaseApp } from '@/config'
import {
  STORAGE_ACCESS_KEY,
  getSettingsState,
  setBizPlaceBanner,
  setRemoteConfigServiceList,
  updateAccessKey,
} from '@/features/store/settings'
import { logWithTag } from '@/features/utils/console'
import { staticBizPlaceBanner, staticServiceList, storageUtil } from '@/utils'

const ua = UAParser()
const LOG = logWithTag('InitializationProvider')
const InitializationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [, setWebEnv] = useAtom(webEnvState)
  const dispatch = useDispatch()
  const store = useSelector(getSettingsState)

  const [isReady, setReady] = useState(false)

  const init = useCallback(() => {
    const remoteConfig = getRemoteConfig(firebaseApp)
    remoteConfig.settings.minimumFetchIntervalMillis = 0 // 설정을 따로 하지 않으면 기본 12시간(43200000)

    Promise.all([
      TmapApp.getUserSetting({ key: 'local.server_type' }),
      TmapApp.getDisplayInfo(),
      TmapApp.getTmapInfo(),
      TmapApp.getAccessKey(),
      TmapApp.getRedDotList(),
    ]).then(([serverType, displayInfo, tmapInfo, accessKey]) => {
      const isPrd = process.env.NEXT_PUBLIC_BUILD_ENV === 'PRD' && serverType !== 'rtg'
      if (serverType === 'rtg') {
        if (
          process.env.NEXT_PUBLIC_TARGET === 'prd' &&
          process.env.NEXT_PUBLIC_BUILD_ENV !== 'RTG'
        ) {
          window.location.replace('https://tmobi-my-rtg.tmapmobility.com')
          return
        } else if (
          process.env.NEXT_PUBLIC_TARGET === 'stg' &&
          process.env.NEXT_PUBLIC_BUILD_ENV !== 'DTG'
        ) {
          window.location.replace('https://tmobi-my-dtg.tmapmobility.com')
          return
        }
      }

      let { deviceId, carrier, euk, sessionId, statusBarHeight } = tmapInfo || {}
      const reg = /^[a-zA-Z\d]*$/
      carrier = reg.test(carrier ?? '') ? carrier : 'ETC'
      const ak = accessKey || getSearchParams().ak || storageUtil.getItem(STORAGE_ACCESS_KEY) || ''
      euk = euk || ''
      dispatch(updateAccessKey(ak))

      // setWebEnv({
      //   accessKey: ak,
      //   deviceId,
      //   carrierName: carrier,
      //   sessionId,
      //   displayInfo: {
      //     ...displayInfo,
      //     statusBarHeight: convertStatusBarHeight,
      //   } as DeviceDisplayInfo,
      // })

      // store.dispatch(envActions.updateState({ tmapInfo: { ...tmapInfo, ak, euk } as ITmapInfo }))

      initFrontmanClient({
        hostUrl: process.env.NEXT_PUBLIC_FRONTMAN_URL,
        headers: {
          Carrier: carrier ?? '',
          'Device-Id': deviceId ?? '',
          'App-Code': 'TMAP',
          'Os-Type': TmapApp.env.isAndroid ? 'AND' : 'IOS',
          'App-Version': TmapApp.env.appVersion,
          'Os-Version': ua.os.version || '',
          'Model-No': ua.device.model || '',
          Using: 'WEB_MY',
          Euk: euk,
        },
        timeout: 10000,
        onRequest(config) {
          config.headers.AccessKey = ak
          return config
        },
        onResponse(response) {
          return response
        },
        onAccessKeyChanged(newAccessKey: string) {
          if (newAccessKey) {
            TmapApp.updateAccessKey({ key: newAccessKey })
            dispatch(updateAccessKey(newAccessKey))
          }
        },
        onAccessKeyExpired(e) {
          if (TmapApp.env.isInApp) {
            TmapApp.getAccessKey().then((newAccessKey) => {
              if (newAccessKey.length) {
                TmapApp.updateAccessKey({ key: newAccessKey })
                dispatch(updateAccessKey(newAccessKey))
                // window.location.reload()
              }
            })
            console.log('onFrontmanAccessKeyExpired === ', e)
          }
        },
        onError(e) {
          console.log('onFrontmanError', e)
        },
      })

      initMockClient({
        onRequest(config) {
          config.headers.AccessKey = store.accessKey
          return config
        },
        onResponse(response) {
          return response
        },
        onAccessKeyChanged(newAccessKey: string) {
          if (newAccessKey) {
            TmapApp.updateAccessKey({ key: newAccessKey })
            dispatch(updateAccessKey(newAccessKey))
          }
        },
        onAccessKeyExpired(e) {
          console.log('onMockAPIAccessKeyExpired === ', e)
        },
        onError(e) {
          console.log('onMockAPIError', e)
        },
      })

      TmapLog.init({
        serviceId: 'my',
        appVersion: TmapApp.env.appVersion,
        carrier: carrier,
        deviceId: deviceId,
        sessionId: sessionId,
        logbox: {
          env: isPrd ? 'PRD' : 'STG',
          token: isPrd ? 'F5CD39ECE530' : '3AF19605D63E',
          appId: 'tmap',
          logVersion: `1.0-${toLower(process.env.NEXT_PUBLIC_BUILD_ENV)}`,
        },
        mixpanel: {
          token: isPrd ? 'cafac3a4974386155257d177fc48437a' : '7600c413c33bbd7e3742b10f1a522f0d',
          identity: euk,
          debug: !isPrd,
        },
        onSend: (tmapLogBuilder) => {
          tmapLogBuilder
            .setPageType('WEB')
            .setCustom('deploy_env', process.env.NEXT_PUBLIC_BUILD_ENV)
            .setEuk(euk)
        },
      })

      fetchAndActivate(remoteConfig)
        .then(() => {
          const serviceList =
            process.env.NEXT_PUBLIC_TARGET !== 'prd'
              ? getValue(remoteConfig, 'my_service_list_STG').asString()
              : getValue(remoteConfig, 'my_service_list').asString()

          const bizPlaceStatus =
            process.env.NEXT_PUBLIC_TARGET !== 'prd'
              ? getValue(remoteConfig, 'my_biz_place_banner_STG').asString()
              : getValue(remoteConfig, 'my_biz_place_banner').asString()

          dispatch(setRemoteConfigServiceList(serviceList))
          dispatch(setBizPlaceBanner(bizPlaceStatus))
        })
        .catch((error) => {
          dispatch(setRemoteConfigServiceList(JSON.stringify(staticServiceList)))
          dispatch(setBizPlaceBanner(JSON.stringify(staticBizPlaceBanner)))

          mixpanel.track(
            error.response?.data
              ? `tscore:error:remoteConfig:INDEX:${error.response.data}`
              : 'tscore:error:remoteConfig:INDEX',
            { error: error }
          )
          datadogRum.addError(error, {
            error: {
              ...error,
              request: error.request.response,
              message: error.response?.data
                ? `tscore:error:remoteConfig:INDEX:${error.response?.data}`
                : 'tscore:error:remoteConfig:INDEX',
            },
          })
        })

      setReady(true)
    })
  }, [dispatch, store.accessKey])

  useEffect(() => {
    init()
  }, [init])

  return isReady ? <>{children}</> : null
}

export { InitializationProvider }
